/** @jsx jsx */
import SimpleContact from "@components/blocks/SimpleContact"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"
import Hero from "@components/Hero"
import Variant from "@components/Variant"

export default function NotFound({
  page: { contact, image, links, text, title },
}) {
  return (
    <Fragment>
      <Variant
        as="section"
        d="flex"
        sx={{
          alignItems: "center",
          flexDirection: "column",
        }}
        variants={["container", "containerSpacing"]}
      >
        <Hero image={image} json={text?.json} links={links} title={title} />
      </Variant>
      <Border />
      <SimpleContact
        mail={contact?.mail}
        mailLinkTitle={contact?.mailLinkTitle}
        mailTitle={contact?.mailTitle}
        messenger={contact?.messenger}
        messengerLinkTitle={contact?.messengerLinkTitle}
        messengerTitle={contact?.messengerTitle}
        phone={contact?.phone}
        phoneLinkTitle={contact?.phoneLinkTitle}
        phoneTitle={contact?.phoneTitle}
      />
    </Fragment>
  )
}

NotFound.propTypes = {
  page: PropTypes.shape({
    contact: PropTypes.object.isRequired,
    image: PropTypes.shape({
      file: PropTypes.object.isRequired,
      fluid: PropTypes.object,
      title: PropTypes.string.isRequired,
    }).isRequired,
    links: PropTypes.array.isRequired,
    text: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
