/**@jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import NotFound from "@components/NotFound"

export default function NotFoundPage({ data: { page } }) {
  return <NotFound page={page} />
}

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape().isRequired,
  }).isRequired,
}

export const query = graphql`
  query GetNotFoundPage($locale: String) {
    page: contentfulNotFoundPage(node_locale: { eq: $locale }) {
      contact {
        mail
        mailLinkTitle
        mailTitle
        messenger
        messengerLinkTitle
        messengerTitle
        phone
        phoneLinkTitle
        phoneTitle
      }
      image {
        fluid(maxWidth: 450, quality: 80) {
          ...GatsbyContentfulFluid
        }
        ...ImageFields
      }
      links {
        href
        id
        slug
        title
        isInternal
      }
      text {
        json
      }
      title
    }
  }
`
